import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Layout from "./components/Layout";
import AboutUs from "./pages/AboutUs";
import Villa1 from "./pages/Villa-1";
import Chalet1 from "./pages/Chalet-1";
import ContactUs from "./pages/ContactUs";
import Bookings from "./pages/Bookings";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout />}></Route>
        <Route path='/AboutUs' element={<AboutUs />}></Route>
        <Route path='/Villa' element={<Villa1 />}></Route>
        <Route path='/Chalet' element={<Chalet1 />}></Route>
        <Route path='/ContactUs' element={<ContactUs />}></Route>
        <Route path='/Bookings' element={<Bookings />}></Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
