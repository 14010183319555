import React, { useState } from "react";
import { PageHeader, Dropdown, Menu, Space } from "antd";
import { Link } from "react-router-dom";
import { slide as Burger, Item } from "burger-menu";
import "burger-menu/lib/index.css";

import MainLogo from "../static/Home/main-logo.png";
import "antd/dist/antd.css";

const { SubMenu } = Menu;

const BurgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [visible, setVisible] = useState(false);

  const hide = () => {
    setVisible(false);
  };

  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };
  function scrollFunction() {
    document.documentElement.scrollTop = 0;
    setVisible(false);
  }
  const menu = (
    <Menu>
      <Menu.Item>
        {" "}
        <Link to='/' onClick={scrollFunction} style={{ color: "white" }}>
          <Item itemKey={"a"} text={"HOME"}></Item>
        </Link>
      </Menu.Item>
      <Menu.Item>
        {" "}
        <Link to='/AboutUs' style={{ color: "white" }}>
          {" "}
          <Item itemKey={"b"} text={"ABOUT US"}></Item>
        </Link>
      </Menu.Item>
      <SubMenu
        title={
          <span style={{ color: "white" }}>
            {" "}
            <Item itemKey={"b"} text={"BOOK NOW"}></Item>
          </span>
        }
      >
        <Menu.Item>
          <Link to='/VILLA' style={{ color: "white" }}>
            {" "}
            <Item itemKey={"c"} text={"VILLA"}></Item>
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to='/CHALET' style={{ color: "white" }}>
            {" "}
            <Item itemKey={"d"} text={"CHALET"}></Item>
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to='/BOOKINGS' style={{ color: "white" }}>
            {" "}
            <Item itemKey={"b"} text={"AVAILABLE DATES"}></Item>
          </Link>
        </Menu.Item>
      </SubMenu>
      <Menu.Item>
        {" "}
        <Link to='/ContactUs' style={{ color: "white" }}>
          {" "}
          <Item itemKey={"d"} text={"CONTACT US"}></Item>
        </Link>{" "}
      </Menu.Item>
    </Menu>
  );

  const content = (
    <div>
      <Link to='/' onClick={scrollFunction} style={{ color: "white" }}>
        <Item itemKey={"a"} text={"HOME"}></Item>
      </Link>
      <Link to='/AboutUs' style={{ color: "white" }}>
        {" "}
        <Item itemKey={"b"} text={"ABOUT US"}></Item>
      </Link>
      <Link to='/ContactUs' style={{ color: "white" }}>
        {" "}
        <Item itemKey={"d"} text={"CONTACT US"}></Item>
      </Link>{" "}
      <Link to='/VILLA' style={{ color: "white" }}>
        {" "}
        <Item itemKey={"c"} text={"VILLA"}></Item>
      </Link>
      <Link to='/CHALET' style={{ color: "white" }}>
        {" "}
        <Item itemKey={"d"} text={"CHALET"}></Item>
      </Link>
      <Link to='/BOOKINGS' style={{ color: "white" }}>
        {" "}
        <Item itemKey={"b"} text={"AVAILABLE DATES"}></Item>
      </Link>
      {/*<SubMenu title="Union Management">
            <Item itemKey={"notice"} text={"Announcement"}></Item>
            <Item itemKey={"union"} text={"Union Inquiries"}></Item>
            <Item itemKey={"entry"} text={"Entry information"}></Item>
          </SubMenu>*/}
    </div>
  );
  return (
    <>
      <Dropdown overlay={menu} trigger={["click"]}>
        <a className='burger-container' onClick={(e) => e.preventDefault()}>
          <div>
            <div id='nav-toggle'>
              <span></span>
            </div>
          </div>{" "}
        </a>
      </Dropdown>
      <Burger
        right
        className='burger-menu'
        isOpen={isOpen}
        selectedKey={"entry"}
        onClose={() => setIsOpen(false)}
      ></Burger>
    </>
  );
};
const MainHeader = () => (
  <div id='Header' className='site-page-header-ghost-wrapper'>
    <PageHeader
      style={{
        backgroundColor: "transparent",
        maxWidth: "90em",

        width: "90vw",
        margin: "auto",
        padding: 0,
      }}
      ghost={false}
      //  onBack={() => window.history.back()}
      title={
        <div>
          <Link to='/'>
            <img
              className='header-logo'
              style={{ width: "12em" }}
              src={MainLogo}
            ></img>{" "}
          </Link>
          <div class='main'>
            <div class='circle'></div>
          </div>
        </div>
      }
      //  subTitle="This is a subtitle"
      extra={[<BurgerMenu />]}
    >
      {/*<Descriptions size="small" column={3}>
         <Descriptions.Item label="Created">Lili Qu</Descriptions.Item>
         <Descriptions.Item label="Association">
           <a>421421</a>
         </Descriptions.Item>
         <Descriptions.Item label="Creation Time">2017-01-10</Descriptions.Item>
         <Descriptions.Item label="Effective Time">2017-10-10</Descriptions.Item>
         <Descriptions.Item label="Remarks">
           Gonghu Road, Xihu District, Hangzhou, Zhejiang, China
         </Descriptions.Item>
       </Descriptions>*/}
    </PageHeader>
  </div>
);

const Header = () => (
  <header className='Homepage'>
    <MainHeader />
  </header>
);

export default Header;
