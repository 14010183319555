import React from "react";
import { Row, Col, Button, Card } from "antd";
import AboutImageHeader from "../static/Home/about-image.png";
import AboutImageHeader1 from "../static/Home/about-image-1.png";
import AboutImageHeader2 from "../static/Home/about-image-2.png";
import AboutImageHeader3 from "../static/Home/about-image-3.png";
import ProductImage1 from "../static/Home/product-image-1.png";
import ProductImage2 from "../static/Home/product-image-2.png";
import ProductImage3 from "../static/Home/product-image-3.png";
import ProductImage4 from "../static/Home/product-image-4.png";
import Sketch3 from "../static/Home/sketch-img-3.png";
import Sketch4 from "../static/Home/sketch-img-4.png";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import Header from "../components/HeaderMobile";
import Footer from "../components/FooterMobile";
import AboutUsMobile from "./AboutUsMobile";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";

const { Meta } = Card;

const AboutHeader = () => (
  <div id='about'>
    <p
      className='Mitr'
      style={{
        fontWeight: 800,
        fontSize: "30px",
        textAlign: "left",
        marginTop: "2em",
        marginBottom: "-0.5em",
        fontFamily: "Mitr",
      }}
    >
      Thala-Thala History
    </p>
  </div>
);
const AboutHeader1 = () => (
  <div id='about'>
    <img src={AboutImageHeader1} style={{ width: "100%" }}></img>{" "}
  </div>
);
const AboutHeader2 = () => (
  <div id='about'>
    <img src={AboutImageHeader2} style={{ width: "100%" }}></img>{" "}
  </div>
);
const AboutHeader3 = () => (
  <div id='about'>
    <img src={AboutImageHeader3} style={{ width: "100%" }}></img>
  </div>
);
const AboutParagraph = () => (
  <div>
    <p
      className='Mitr'
      style={{
        fontWeight: 500,
        fontSize: 16,
        marginTop: "1.5rem",
        textAlign: "left",
        color: "black",
      }}
    >
      The farm Leeukloof 275JR 71 was bought in 1992 by Frans and Eleanor
      Erasmus from Danie Joubert, a nature conservationist. Danie had built the
      rondavel (Thala-Thala Chalet) from rock gathered in the surrounding hills
      in the immediate area. The main house was partially built but was never
      occupied. Part of the terraces that still form part of the lush garden was
      also his initiative.
      <br />
      The rondavel served as accommodation those days for family and friends for
      weekend breakaways and school holidays. During the winter of 1993, a
      devastating fire destroyed majestic Boekenhout trees and shrubs; the
      burned remains (monuments) are still evident today. <br />
      In 1995 the surrounding landowners agreed to fence in 5 neighbouring farms
      to establish a 105-hectare conservancy. The first game was introduced soon
      afterwards.
      <br />
      The main reason the current owners decided to give up the city life was
      the need to bring up our children in the countryside and live close to
      nature. Many hours spent on the road commuting kids to school and driving
      to work sacrificed for the pleasure of living in the countryside.
      <br />
      In 1998 the building of Thala-Thala Villa and extension of the main house
      commenced. Both dwellings were complete and occupied in the summer of
      2001. <br />
      Grandpa Frans and Grandma Eleanor decided to give up country living in
      2016 and retire closer to Pretoria. The main house became the new
      residence for the Fick family, and Thala-Thala Villa was subsequently
      rented. <br />
      The rondavel was standing empty, and in 2017 the owners decided to
      renovate and share the magnificent views and the tranquillity of the
      Leeukloof valley. Thala-Thala Chalet was and still is a roaring success,
      occupying 98% on weekends and school holidays.
      <br />
      In 2021 when the last tenants of the Villa departed, it was logical to
      renovate and remodel the Villa to accommodate bigger groups and family
      gatherings of up to 12 guests. The Villa has proved very popular, with a
      weekend occupancy of over 80%.
      <br />
    </p>
  </div>
);
const AboutParagraph1 = () => (
  <div>
    <p
      className='Mitr'
      style={{
        fontWeight: 500,
        fontSize: 22,
        marginTop: "1.5rem",
        color: "black",
      }}
    >
      Customised Plastic Products (CPP) is also a founding member of IFPA
      (Installation and Fabrication Plastic Pipe Association) an affiliate
      association to SAPPMA (South African Plastic Pipe Manufacturers
      Association). The main objective of IFPA and its member companies is to
      ensure consistent high quality installations and fabrications. To this end
      all member companies commit to abide to a code of ethics to ensure
      quality.
    </p>{" "}
  </div>
);
const AboutParagraph2 = () => (
  <div>
    <p
      className='Mitr'
      style={{
        fontWeight: 500,
        fontSize: 22,
        marginTop: "1.5rem",
        color: "black",
      }}
    >
      CPP has a wealth of experience in the field of fabricating and
      installations in high risk dolomitic areas. All welding teams are
      qualified and certified to meet the stringent requirements set out by the
      Department of Public Works. Processes employed, equipment and materials
      used is well maintained and meet both International and South African
      requirements. CPP have successfully completed the supply and Installation
      of Electrical-; Storm water- and Sewer
    </p>{" "}
  </div>
);
const AboutParagraph2Extra = () => (
  <div>
    <p
      className='Mitr'
      style={{
        fontWeight: 500,
        fontSize: 22,
        marginTop: "3rem",
        color: "black",
      }}
    >
      Manholes for the Waterkloof Air Force Base Presidential Suite and Main
      Runway rehabilitation project. CPP was subsequently also appointed to
      perform storm water pipe welding on the Secondary runway upgrade project.
      Other DPW projects successfully completed are: Thaba Tswane Nurses College
      Storm water Pipe and Manholes, Westonaria Court & 68 Air School
      rehabilitation of wet services and supply pre-fabricated HDPE manholes.
    </p>{" "}
  </div>
);
const AboutParagraph3 = () => (
  <div>
    <p
      className='Mitr'
      style={{
        fontWeight: 500,
        fontSize: 22,
        marginTop: "1.5rem",
        color: "black",
      }}
    >
      Various Mining - and Industrial projects have also been undertaken,
      typical examples are design and fabricate acid tanks for surface treatment
      plants and HDPE Vacuum filtration systems for mine processing plants,
      meeting high quality requirements and close tolerances.
      <br />
      CPP is conversant and equipped to perform all types of welding e.g.
      Butt-welding (pipe sizes 40mm - 500 OD); Electro-fusion welding (20mm to
      1200mm OD) ; Socket-fusion- and Extrusion welding. In accordance with DPW
      high risk dolomitic requirements Butt-Welding equipment meet the
      requirements of SANS 1671-1. In addition to site welding CPP also offers a
      pipe testing service both hydrostatic and Air testing in accordance with
      SABS 1200.
    </p>{" "}
  </div>
);
const items = [
  <Fade bottom>
    <Card
      className='item'
      data-value='1'
      style={{
        backgroundColor: "rgba(255, 255, 255, 0.85)",
        margin: "1em",
        padding: "1em",
        borderRadius: "1em",
        border: "none",

        textAlign: "center",
      }}
      // hoverable
    >
      <Meta
        title='IRRIGATION FLOATING PUMPS'
        description={
          <span>
            <img
              className='products-img'
              style={{
                margin: "auto",
                marginTop: "1em",
                textAlign: "center",
                width: "23em",
              }}
              src={ProductImage1}
            ></img>
            <p
              className='Mitr'
              style={{
                fontWeight: 500,
                fontSize: 18,
                marginTop: "1.5rem",
                color: "black",
              }}
            >
              Description
            </p>{" "}
          </span>
        }
      />
      <div className='home-button'>
        <Link to='/Projects'>
          <Button
            className='my-button'
            style={{
              borderRadius: 8,
              fontWeight: 1000,
              height: "2.3em",
              width: "8.5em",
              color: "#281562",
              border: "3px solid #281562",
              fontSize: "17px",
              backgroundColor: "transparent",
              marginTop: "1em",
              fontFamily: "Mitr",
            }}
            type='primary'
          >
            LEARN MORE
          </Button>
        </Link>{" "}
        <Link to='/ContactUs'>
          <Button
            className='my-button'
            style={{
              borderRadius: 8,
              fontWeight: 700,
              height: "2.3em",
              width: "8.5em",
              color: "white",
              border: "3px solid #281562",
              fontSize: "17px",
              backgroundColor: "#281562",
              marginTop: "1em",
              marginLeft: "0.5em",
              fontFamily: "Mitr",
            }}
            type='primary'
          >
            CONTACT US
          </Button>
        </Link>
      </div>
    </Card>
  </Fade>,
  <Fade bottom>
    <Card
      className='item'
      data-value='2'
      style={{
        backgroundColor: "rgba(255, 255, 255, 0.85)",
        margin: "1em",
        padding: "1em",
        borderRadius: "1em",
        border: "none",
        boxShadow: "8px 5px 15px #00000062",
        textAlign: "center",
      }}
      // hoverable
    >
      <Meta
        title='TELECOMMUNICATION MANHOLES'
        description={
          <span>
            <img
              className='products-img'
              style={{
                margin: "auto",
                marginTop: "1em",
                textAlign: "center",
                width: "23em",
              }}
              src={ProductImage2}
            ></img>
            <p
              className='Mitr'
              style={{
                fontWeight: 500,
                fontSize: 18,
                marginTop: "1.5rem",
                color: "black",
              }}
            >
              Description
            </p>{" "}
          </span>
        }
      />
      <div className='home-button'>
        <Link to='/Projects'>
          <Button
            className='my-button'
            style={{
              borderRadius: 8,
              fontWeight: 1000,
              height: "2.3em",
              width: "8.5em",
              color: "#281562",
              border: "3px solid #281562",
              fontSize: "17px",
              backgroundColor: "transparent",
              marginTop: "1em",
              fontFamily: "Mitr",
            }}
            type='primary'
          >
            LEARN MORE
          </Button>
        </Link>{" "}
        <Link to='/ContactUs'>
          <Button
            className='my-button'
            style={{
              borderRadius: 8,
              fontWeight: 700,
              height: "2.3em",
              width: "8.5em",
              color: "white",
              border: "3px solid #281562",
              fontSize: "17px",
              backgroundColor: "#281562",
              marginTop: "1em",
              marginLeft: "0.5em",
              fontFamily: "Mitr",
            }}
            type='primary'
          >
            CONTACT US
          </Button>
        </Link>
      </div>
    </Card>
  </Fade>,
  <Fade bottom>
    <Card
      className='item'
      data-value='3'
      style={{
        backgroundColor: "rgba(255, 255, 255, 0.85)",
        margin: "1em",
        padding: "1em",
        borderRadius: "1em",
        border: "none",

        textAlign: "center",
      }}
      // hoverable
    >
      <Meta
        title='GAS LINES'
        description={
          <span>
            <img
              className='products-img'
              style={{
                margin: "auto",
                marginTop: "1em",
                textAlign: "center",
                width: "23em",
              }}
              src={ProductImage3}
            ></img>
            <p
              className='Mitr'
              style={{
                fontWeight: 500,
                fontSize: 18,
                marginTop: "1.5rem",
                color: "black",
              }}
            >
              Description
            </p>{" "}
          </span>
        }
      />
      <div className='home-button'>
        <Link to='/Projects'>
          <Button
            className='my-button'
            style={{
              borderRadius: 8,
              fontWeight: 1000,
              height: "2.3em",
              width: "8.5em",
              color: "#281562",
              border: "3px solid #281562",
              fontSize: "17px",
              backgroundColor: "transparent",
              marginTop: "1em",
              fontFamily: "Mitr",
            }}
            type='primary'
          >
            LEARN MORE
          </Button>
        </Link>{" "}
        <Link to='/ContactUs'>
          <Button
            className='my-button'
            style={{
              borderRadius: 8,
              fontWeight: 700,
              height: "2.3em",
              width: "8.5em",
              color: "white",
              border: "3px solid #281562",
              fontSize: "17px",
              backgroundColor: "#281562",
              marginTop: "1em",
              marginLeft: "0.5em",
              fontFamily: "Mitr",
            }}
            type='primary'
          >
            CONTACT US
          </Button>
        </Link>
      </div>
    </Card>
  </Fade>,
  <Fade bottom>
    <Card
      className='item'
      data-value='4'
      style={{
        backgroundColor: "rgba(255, 255, 255, 0.85)",
        margin: "1em",
        padding: "1em",
        borderRadius: "1em",
        border: "none",

        textAlign: "center",
      }}
      // hoverable
    >
      <Meta
        title='HYDRAULIC TESTING'
        description={
          <span>
            <img
              className='products-img'
              style={{
                margin: "auto",
                marginTop: "1em",
                textAlign: "center",
                width: "23em",
              }}
              src={ProductImage4}
            ></img>
            <p
              className='Mitr'
              style={{
                fontWeight: 500,
                fontSize: 18,
                marginTop: "1.5rem",
                color: "black",
              }}
            >
              Description
            </p>{" "}
          </span>
        }
      />
      <div className='home-button'>
        <Link to='/Projects'>
          <Button
            className='my-button'
            style={{
              borderRadius: 8,
              fontWeight: 1000,
              height: "2.3em",
              width: "8.5em",
              color: "#281562",
              border: "3px solid #281562",
              fontSize: "17px",
              backgroundColor: "transparent",
              marginTop: "1em",
              fontFamily: "Mitr",
            }}
            type='primary'
          >
            LEARN MORE
          </Button>
        </Link>{" "}
        <Link to='/ContactUs'>
          <Button
            className='my-button'
            style={{
              borderRadius: 8,
              fontWeight: 700,
              height: "2.3em",
              width: "8.5em",
              color: "white",
              border: "3px solid #281562",
              fontSize: "17px",
              backgroundColor: "#281562",
              marginTop: "1em",
              marginLeft: "0.5em",
              fontFamily: "Mitr",
            }}
            type='primary'
          >
            CONTACT US
          </Button>
        </Link>
      </div>
    </Card>
  </Fade>,
];
const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 3 },
};
const Home = () => (
  <div>
    <AboutUsMobile />
    <div className='mobile-view'>
      <Header />
      <Row
        className='about-row'
        style={{
          margin: "auto",

          marginTop: "-1em",
        }}
      >
        <Row style={{ maxWidth: "85em", margin: "auto", padding: "1.2em" }}>
          <Col sm={24} md={24} style={{ marginTop: "1em" }}>
            <AboutHeader />
          </Col>{" "}
          <Col sm={24} md={24} style={{ marginTop: "1.5em" }}>
            <AboutParagraph />
          </Col>{" "}
        </Row>{" "}
      </Row>
      <Footer />
    </div>
  </div>
);

export default Home;
