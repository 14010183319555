import React, { Component } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Card,
  Rate,
  Progress,
  Carousel,
  Image,
} from "antd";
import styled, { css, keyframes } from "styled-components";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import AboutImage from "../static/Home/background-ellipse1.png";
import AboutImageHeader from "../static/Home/about-image.png";
import AboutImageHeader1 from "../static/Home/about-image-1.png";
import AboutImageHeader2 from "../static/Home/about-image-2.png";
import AboutImageHeader3 from "../static/Home/about-image-3.png";
import ProductImage1 from "../static/Home/product-image-1.png";
import ProductImage2 from "../static/Home/product-image-2.png";
import ProductImage3 from "../static/Home/product-image-3.png";
import ProductImage4 from "../static/Home/product-image-4.png";
import ProductImage5 from "../static/Home/chalet-1.png";
import BedImg from "../static/Home/bed-img.svg";
import CouchImg from "../static/Home/couch-img.svg";
import Sketch3 from "../static/Home/sketch-img-3.png";
import Sketch5 from "../static/Home/sketch-img-5.png";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

import Header from "../components/HeaderMobile";
import Footer from "../components/FooterMobile";
import { CarOutlined } from "@ant-design/icons";

import { Link } from "react-router-dom";

const { Meta } = Card;
const StyledCard = styled(Card)`
  .ant-card-body {
    padding: 10px !important;
  }
  .ant-card-meta-title {
    font-weight: 700 !important;
    font-size: 25px !important;
  }
`;

class Chalet extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    const array = [
      "IMG_5124",
      "IMG_5117",
      "IMG_5118",
      "IMG_5119",
      "IMG_5120",
      "IMG_5121",
      "IMG_5122",
      "IMG_5123",
      "IMG_5125",
      "IMG_5126",
      "IMG_5127",
      "IMG_5128",
      "IMG_5129",
      "IMG_5130",
      "IMG_5131",
      "IMG_5132",
      "IMG_5133",
      "IMG_5135",
      "IMG_5136",
      "IMG_5137",
      "IMG_5138",
      "IMG_5139",
      "IMG_5140",
      "IMG_5141",
      "IMG_5142",
      "IMG_5143",
      "IMG_5144",
      "IMG_5145",
      "IMG_5146",
      "IMG_5147",
      "IMG_5148",
      "IMG_5149",
      "IMG_5151",
      "IMG_5152",
      "IMG_5153",
      "IMG_5154",
      "IMG_5155",
    ];

    const images = array.map((image) => {
      return (
        <div style={{ width: "100vw" }}>
          <Image
            style={{
              margin: "auto",
              width: "100vw",
              height: "24em",
              borderRadius: "0.5em",
              objectFit: "cover",
            }}
            key={image}
            src={require(`../static/ThalaThala_Chalet_Image/${image}.png`)}
          />
        </div>
      );
    });

    return (
      <div style={{}}>
        <Row style={{ margin: "auto", maxWidth: "65em" }}>
          {" "}
          <Col sm={24} md={24} style={{ margin: "auto", width: "100vw" }}>
            <Image.PreviewGroup>
              <Slide
                autoplay={false}
                transitionDuration={400}
                className='each-Slide'
              >
                {images}
              </Slide>
            </Image.PreviewGroup>
          </Col>
        </Row>
      </div>
    );
  }
}

const AboutHeader = () => (
  <div id='about'>
    <p
      className='Mitr'
      style={{
        margin: "auto",
        color: "black",
        fontWeight: 800,
        fontSize: "27px",
        marginBottom: "-0em",
        fontFamily: "Mitr",
      }}
    >
      <strong>Thala-Thala Chalet</strong>
    </p>
  </div>
);

const Project = () => (
  <div>
    <div className='mobile-view'>
      <Header />
      <Row style={{ marginTop: "6em", textAlign: "center" }}>
        <Col
          sm={24}
          md={24}
          style={{ margin: "auto", marginTop: "0em", textAlign: "center" }}
        >
          <AboutHeader />
        </Col>
      </Row>
      <Row style={{ maxWidth: "100em", margin: "auto", marginTop: "0em" }}>
        <StyledCard
          className='item'
          data-value='1'
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.85)",
            margin: "auto",
            padding: "1em",
            borderRadius: "1em",
            border: "none",

            maxWidth: "100em",
            width: "100%",
            textAlign: "center",
          }}
          // hoverable
        >
          <Meta
            //title='LANDFILL GAS TO ELECTRICITY ENERGY'
            description={
              <span
                style={{
                  margin: "auto",
                  textAlign: "center",
                }}
              >
                <Chalet />
                <Row
                  style={{
                    margin: "auto",
                    marginTop: "0.5em",
                    maxWidth: "60em",
                  }}
                >
                  <Col span={24}>
                    <Rate disabled allowHalf defaultValue={4.5} />{" "}
                    <span className='ant-rate-text'>4.9</span>
                  </Col>
                  <Col span={24}>
                    {" "}
                    <p
                      className='Mitr'
                      style={{
                        margin: "auto",
                        marginTop: "0.5em",
                        color: "black",
                        fontWeight: 800,
                        fontSize: "20px",
                        marginBottom: "-0em",
                        fontFamily: "Mitr",
                        fontFamily: "Mitr",
                      }}
                    >
                      From{" "}
                      <span style={{ fontSize: 25, fontWeight: 700 }}>
                        R450{" "}
                      </span>
                      per person
                    </p>
                  </Col>{" "}
                </Row>{" "}
                <Row
                  style={{
                    margin: "auto",
                    marginTop: "1em",
                    textAlign: "center",
                  }}
                >
                  <Col span={10}>
                    <Link to='/ContactUs'>
                      <Button
                        className='my-button'
                        style={{
                          borderRadius: 80,
                          fontWeight: 500,
                          height: "2.6em",
                          paddingRight: "0.5em",
                          paddingLeft: "0.5em",
                          color: "white",
                          border: "none",
                          boxShadow: "8px 5px 15px #00000062",
                          backgroundColor: "#2D7A75",
                          marginBottom: "1em",
                          fontFamily: "Mitr",
                          width: "90%",
                        }}
                        type='primary'
                        htmlType='submit'
                      >
                        CONTACT US
                      </Button>
                    </Link>
                  </Col>
                  <Col span={14}>
                    <Link to='/Bookings'>
                      <Button
                        className='my-button'
                        style={{
                          borderRadius: 80,
                          fontWeight: 500,
                          height: "2.6em",
                          paddingRight: "0.5em",
                          paddingLeft: "0.5em",
                          color: "white",
                          border: "none",
                          boxShadow: "8px 5px 15px #00000062",
                          backgroundColor: "#2D7A75",
                          marginTop: "0em",
                          fontFamily: "Mitr",
                          width: "90%",
                        }}
                        type='primary'
                        htmlType='submit'
                      >
                        AVAILABLE DATES
                      </Button>{" "}
                    </Link>
                  </Col>
                </Row>{" "}
                <p
                  className='Mitr'
                  style={{
                    textAlign: "left",
                    margin: "auto",
                    color: "black",
                    fontWeight: 800,
                    fontSize: "20px",
                    marginTop: "1em",
                    fontFamily: "Mitr",
                  }}
                >
                  Description
                </p>
                <p
                  className='Mitr'
                  style={{
                    textAlign: "left",
                    fontWeight: 500,
                    fontSize: 17,
                    marginTop: "1em",
                    color: "black",
                  }}
                >
                  Thala Thala Chalet is a spacious self-catering unit for 4
                  people on a farm in Leeukloof near Dinokeng, approximately
                  45km from Pretoria.
                  <br />
                  <br />
                  Country living with all the amenities you will enjoy in the
                  city. A secure Thatch chalet built from rock.
                  <br />
                  Situated on a 21ha bushveld farm. Lots of bird life, Impala
                  and Blesbok roaming around.
                  <br />
                  <br />
                  1 Bedroom with queen size bed and bathroom en-suite. <br />
                  Open plan living area with fully equipped kitchen dining room
                  and lounge with queen size double sleeper couch and DSTV.
                  <br />
                  <br />
                  Cool veranda amongst trees. Beautifull terraced garden with
                  (boma) barbecue area. <br />
                  <br />
                  Under cover parking and a shared pool.
                </p>{" "}
                <Row
                  style={{
                    margin: "auto",
                    marginTop: "1.5em",
                    textAlign: "center",
                  }}
                >
                  <Col span={10}>
                    <Link to='/ContactUs'>
                      <Button
                        className='my-button'
                        style={{
                          borderRadius: 80,
                          fontWeight: 500,
                          height: "2.6em",
                          paddingRight: "0.5em",
                          paddingLeft: "0.5em",
                          color: "white",
                          border: "none",
                          boxShadow: "8px 5px 15px #00000062",
                          backgroundColor: "#2D7A75",
                          marginBottom: "1em",
                          fontFamily: "Mitr",
                          width: "90%",
                        }}
                        type='primary'
                        htmlType='submit'
                      >
                        CONTACT US
                      </Button>
                    </Link>
                  </Col>
                  <Col span={14}>
                    <Link to='/Bookings'>
                      <Button
                        className='my-button'
                        style={{
                          borderRadius: 80,
                          fontWeight: 500,
                          height: "2.6em",
                          paddingRight: "0.5em",
                          paddingLeft: "0.5em",
                          color: "white",
                          border: "none",
                          boxShadow: "8px 5px 15px #00000062",
                          backgroundColor: "#2D7A75",
                          marginTop: "0em",
                          fontFamily: "Mitr",
                          width: "90%",
                        }}
                        type='primary'
                        htmlType='submit'
                      >
                        AVAILABLE DATES
                      </Button>{" "}
                    </Link>
                  </Col>
                </Row>{" "}
              </span>
            }
          />
        </StyledCard>
      </Row>
      <Row style={{ maxWidth: "92em", margin: "auto", marginTop: "1em" }}>
        <Col span={12} style={{ padding: "0.5em" }}>
          {" "}
          <StyledCard
            style={{
              margin: "auto",

              textAlign: "center",
              backgroundColor: "#F0F0F0",
              height: "13em",
              boxShadow: " 0 12px 15px 0 rgb(0 0 0 / 25%)",
            }}
          >
            <img
              src={BedImg}
              style={{
                width: "3em",
                marginBottom: "1em",
                marginTop: "1.5em",
              }}
            />
            <h1
              style={{
                fontWeight: 500,
                fontSize: 17,
                marginTop: "-0.5em",
                color: "black",
              }}
            >
              Bedroom 1
            </h1>
            <p
              style={{
                fontWeight: 400,
                fontSize: 15,
                marginTop: "-0.5em",
                color: "black",
              }}
            >
              Sleeps 2 people
            </p>
            <p
              style={{
                fontWeight: 400,
                fontSize: 15,
                marginTop: "-1em",
                color: "black",
              }}
            >
              1 x King Bed
            </p>
          </StyledCard>
        </Col>{" "}
        <Col span={12} style={{ padding: "0.5em" }}>
          {" "}
          <StyledCard
            style={{
              margin: "auto",

              textAlign: "center",
              backgroundColor: "#F0F0F0",
              height: "13em",
              boxShadow: " 0 12px 15px 0 rgb(0 0 0 / 25%)",
            }}
          >
            <img
              src={CouchImg}
              style={{
                width: "3.5em",
                marginBottom: "1em",
                marginTop: "1.5em",
              }}
            />
            <h1
              style={{
                fontWeight: 500,
                fontSize: 17,
                marginTop: "-0.5em",
                color: "black",
              }}
            >
              Area 1
            </h1>
            <p
              style={{
                fontWeight: 400,
                fontSize: 15,
                marginTop: "-0.5em",
                color: "black",
              }}
            >
              Sleeps 2 people
            </p>
            <p
              style={{
                fontWeight: 400,
                fontSize: 15,
                marginTop: "-1em",
                lineHeight: "1.2em",
                color: "black",
              }}
            >
              1 x Double Sleeper Couch
            </p>
          </StyledCard>
        </Col>{" "}
      </Row>{" "}
      <Row style={{ maxWidth: "92em", margin: "auto", marginTop: "0.5em" }}>
        <Col span={24} style={{ padding: "1em" }}>
          {" "}
          <StyledCard
            style={{
              margin: "auto",
              paddingTop: "1em",
              textAlign: "left",
              backgroundColor: "#F0F0F0",

              boxShadow: " 0 12px 15px 0 rgb(0 0 0 / 25%)",
            }}
          >
            <Row>
              <Col span={12}>
                <h1
                  style={{
                    fontWeight: 500,
                    fontSize: 22,
                    marginLeft: "0.5em",
                    marginTop: "-0.5em",
                    color: "black",
                  }}
                >
                  Facilities
                </h1>
                <ul>
                  <li
                    style={{
                      fontWeight: 500,
                      fontSize: 15,
                      marginTop: "0em",
                      color: "black",
                      fontFamily: "Mitr",
                    }}
                  >
                    Swimming Pool
                  </li>
                  <li
                    style={{
                      fontWeight: 500,
                      fontSize: 15,
                      marginTop: "0em",
                      color: "black",
                      fontFamily: "Mitr",
                    }}
                  >
                    Kitchen Facilities
                  </li>
                  <li
                    style={{
                      fontWeight: 500,
                      fontSize: 15,
                      marginTop: "0em",
                      color: "black",
                      fontFamily: "Mitr",
                    }}
                  >
                    Malaria Free
                  </li>{" "}
                  <li
                    style={{
                      fontWeight: 500,
                      fontSize: 15,
                      marginTop: "0em",
                      color: "black",
                      fontFamily: "Mitr",
                    }}
                  >
                    Satellite TV
                  </li>{" "}
                  <li
                    style={{
                      fontWeight: 500,
                      fontSize: 15,
                      marginTop: "0em",
                      color: "black",
                      fontFamily: "Mitr",
                    }}
                  >
                    Serviced On Demand
                  </li>
                </ul>
              </Col>
              <Col span={12}>
                <div
                  style={{
                    fontWeight: 500,
                    fontSize: 22,
                    marginLeft: "0.5em",
                    marginTop: "1.5em",
                    color: "black",
                  }}
                ></div>
                <ul>
                  <li
                    style={{
                      fontWeight: 500,
                      fontSize: 15,
                      marginTop: "0em",
                      color: "black",
                      fontFamily: "Mitr",
                    }}
                  >
                    Braai Area
                  </li>
                  <li
                    style={{
                      fontWeight: 500,
                      fontSize: 15,
                      marginTop: "0em",
                      color: "black",
                      fontFamily: "Mitr",
                    }}
                  >
                    No Smoking Indoors
                  </li>
                  <li
                    style={{
                      fontWeight: 500,
                      fontSize: 15,
                      marginTop: "0em",
                      color: "black",
                      fontFamily: "Mitr",
                    }}
                  >
                    Personal Safe
                  </li>{" "}
                  <li
                    style={{
                      fontWeight: 500,
                      fontSize: 15,
                      marginTop: "0em",
                      color: "black",
                      fontFamily: "Mitr",
                    }}
                  >
                    Self Catering
                  </li>{" "}
                  <li
                    style={{
                      fontWeight: 500,
                      fontSize: 15,
                      marginTop: "0em",
                      color: "black",
                      fontFamily: "Mitr",
                    }}
                  >
                    Free WiFi
                  </li>
                </ul>
              </Col>
            </Row>
          </StyledCard>
        </Col>{" "}
        <Col span={24} style={{ padding: "1em" }}>
          <StyledCard
            style={{
              margin: "auto",
              paddingTop: "1em",
              textAlign: "left",
              backgroundColor: "#F0F0F0",
              height: "15em",
              boxShadow: " 0 12px 15px 0 rgb(0 0 0 / 25%)",
            }}
          >
            <h1
              style={{
                fontWeight: 500,
                fontSize: 22,
                marginTop: "-0.5em",
                color: "black",
                fontFamily: "Mitr",
              }}
            >
              Activities Onsite
            </h1>
            <ul>
              <li
                style={{
                  fontWeight: 500,
                  fontSize: 15,
                  marginTop: "0em",
                  color: "black",
                  fontFamily: "Mitr",
                }}
              >
                Bird Watching
              </li>
              <li
                style={{
                  fontWeight: 500,
                  fontSize: 15,
                  marginTop: "0em",
                  color: "black",
                  fontFamily: "Mitr",
                }}
              >
                Game Viewing
              </li>
              <li
                style={{
                  fontWeight: 500,
                  fontSize: 15,
                  marginTop: "0em",
                  color: "black",
                  fontFamily: "Mitr",
                }}
              >
                Swimming
              </li>{" "}
              <li
                style={{
                  fontWeight: 500,
                  fontSize: 15,
                  marginTop: "0em",
                  color: "black",
                  fontFamily: "Mitr",
                }}
              >
                Cycling
              </li>{" "}
              <li
                style={{
                  fontWeight: 500,
                  fontSize: 15,
                  marginTop: "0em",
                  color: "black",
                  fontFamily: "Mitr",
                }}
              >
                Mountain Biking
              </li>
            </ul>
          </StyledCard>
        </Col>
      </Row>
      <Row style={{ maxWidth: "92em", margin: "auto", marginTop: "0em" }}>
        <Col span={24} style={{ padding: "1em" }}>
          {" "}
          <StyledCard
            style={{
              margin: "auto",
              paddingTop: "1em",
              textAlign: "left",
              backgroundColor: "#F0F0F0",

              boxShadow: " 0 12px 15px 0 rgb(0 0 0 / 25%)",
            }}
          >
            <Row>
              <Col span={12}>
                <h1
                  style={{
                    fontWeight: 500,
                    fontSize: 22,
                    marginLeft: "0.5em",
                    whiteSpace: "nowrap",
                    marginTop: "-0.5em",
                    color: "black",
                  }}
                >
                  Activities Nearby
                </h1>
                <ul>
                  <li
                    style={{
                      fontWeight: 500,
                      fontSize: 15,
                      marginTop: "0em",
                      color: "black",
                      fontFamily: "Mitr",
                    }}
                  >
                    Horse Riding
                  </li>
                  <li
                    style={{
                      fontWeight: 500,
                      fontSize: 15,
                      marginTop: "0em",
                      color: "black",
                      fontFamily: "Mitr",
                    }}
                  >
                    Fishing
                  </li>
                  <li
                    style={{
                      fontWeight: 500,
                      fontSize: 15,
                      marginTop: "0em",
                      color: "black",
                      fontFamily: "Mitr",
                    }}
                  >
                    Bird Watching
                  </li>{" "}
                  <li
                    style={{
                      fontWeight: 500,
                      fontSize: 15,
                      marginTop: "0em",
                      color: "black",
                      fontFamily: "Mitr",
                    }}
                  >
                    Game Drives
                  </li>{" "}
                  <li
                    style={{
                      fontWeight: 500,
                      fontSize: 15,
                      marginTop: "0em",
                      color: "black",
                      fontFamily: "Mitr",
                    }}
                  >
                    Mountain Biking
                  </li>
                </ul>
              </Col>
              <Col span={12}>
                <div
                  style={{
                    fontWeight: 500,
                    fontSize: 22,
                    marginLeft: "0.5em",
                    marginTop: "1.5em",
                    color: "black",
                  }}
                ></div>
                <ul>
                  <li
                    style={{
                      fontWeight: 500,
                      fontSize: 15,
                      marginTop: "0em",
                      color: "black",
                      fontFamily: "Mitr",
                    }}
                  >
                    Fly Fishing
                  </li>
                  <li
                    style={{
                      fontWeight: 500,
                      fontSize: 15,
                      marginTop: "0em",
                      color: "black",
                      fontFamily: "Mitr",
                    }}
                  >
                    Big Five
                  </li>
                  <li
                    style={{
                      fontWeight: 500,
                      fontSize: 15,
                      marginTop: "0em",
                      color: "black",
                      fontFamily: "Mitr",
                    }}
                  >
                    Cycling
                  </li>{" "}
                  <li
                    style={{
                      fontWeight: 500,
                      fontSize: 15,
                      marginTop: "0em",
                      color: "black",
                      fontFamily: "Mitr",
                    }}
                  >
                    Game Viewing
                  </li>{" "}
                  <li
                    style={{
                      fontWeight: 500,
                      fontSize: 15,
                      marginTop: "0em",
                      color: "black",
                      fontFamily: "Mitr",
                    }}
                  >
                    Swimming
                  </li>
                </ul>
              </Col>
            </Row>
          </StyledCard>
        </Col>{" "}
        <Col span={24} style={{ padding: "1em" }}>
          {" "}
          <StyledCard
            style={{
              margin: "auto",
              paddingTop: "1em",
              textAlign: "left",
              backgroundColor: "#F0F0F0",

              boxShadow: " 0 12px 15px 0 rgb(0 0 0 / 25%)",
            }}
          >
            <h1
              style={{
                fontWeight: 500,
                fontSize: 22,
                marginTop: "-0.5em",
                marginLeft: "0.5em",
                color: "black",
              }}
            >
              Parking Facilities
            </h1>
            <ul>
              <li
                style={{
                  fontWeight: 500,
                  fontSize: 15,

                  color: "black",
                  fontFamily: "Mitr",
                }}
              >
                Secure Parking
              </li>
              <li
                style={{
                  fontWeight: 500,
                  fontSize: 15,
                  marginTop: "0em",
                  color: "black",
                  fontFamily: "Mitr",
                }}
              >
                Covered Parking
              </li>
              <li
                style={{
                  fontWeight: 500,
                  fontSize: 15,
                  marginTop: "0em",
                  color: "black",
                  fontFamily: "Mitr",
                }}
              >
                Behind remote Gate
              </li>{" "}
            </ul>
          </StyledCard>
        </Col>
      </Row>
      <Row style={{ maxWidth: "92em", margin: "auto", marginTop: "0em" }}>
        <Col span={24} style={{ padding: "1em" }}>
          {" "}
          <StyledCard
            style={{
              margin: "auto",
              paddingTop: "1em",
              textAlign: "left",
              backgroundColor: "#F0F0F0",

              boxShadow: " 0 12px 15px 0 rgb(0 0 0 / 25%)",
            }}
          >
            <Row>
              <Col span={12}>
                <h1
                  style={{
                    fontWeight: 500,
                    fontSize: 22,
                    marginLeft: "0.5em",
                    marginTop: "-0.5em",
                    color: "black",
                    whiteSpace: "nowrap",
                  }}
                >
                  Languages Spoken
                </h1>
                <ul>
                  <li
                    style={{
                      fontWeight: 500,
                      fontSize: 15,
                      marginTop: "0em",
                      color: "black",
                      fontFamily: "Mitr",
                    }}
                  >
                    Afrikaans
                  </li>
                  <li
                    style={{
                      fontWeight: 500,
                      fontSize: 15,
                      marginTop: "0em",
                      color: "black",
                      fontFamily: "Mitr",
                    }}
                  >
                    English
                  </li>
                </ul>
              </Col>
            </Row>
          </StyledCard>
        </Col>{" "}
        <Col span={24} style={{ padding: "1em" }}>
          {" "}
          <StyledCard
            style={{
              margin: "auto",
              paddingTop: "1em",
              textAlign: "left",
              backgroundColor: "#F0F0F0",

              boxShadow: " 0 12px 15px 0 rgb(0 0 0 / 25%)",
            }}
          >
            <Row>
              <Col span={12}>
                <h1
                  style={{
                    fontWeight: 500,
                    fontSize: 22,
                    marginLeft: "0.5em",
                    marginTop: "-0.5em",
                    color: "black",
                    whiteSpace: "nowrap",
                  }}
                >
                  Conferencing Facilities
                </h1>
                <ul>
                  <li
                    style={{
                      fontWeight: 500,
                      fontSize: 15,
                      marginTop: "0em",
                      color: "black",
                      fontFamily: "Mitr",
                    }}
                  >
                    Internet
                  </li>
                  <li
                    style={{
                      fontWeight: 500,
                      fontSize: 15,
                      marginTop: "0em",
                      color: "black",
                      fontFamily: "Mitr",
                    }}
                  >
                    Television
                  </li>
                </ul>
              </Col>
            </Row>
          </StyledCard>
        </Col>{" "}
      </Row>{" "}
      <Row style={{ maxWidth: "92em", margin: "auto", marginTop: "0em" }}>
        <Col span={24} style={{ padding: "1em" }}>
          {" "}
          <StyledCard
            style={{
              margin: "auto",
              paddingTop: "1em",
              textAlign: "left",
              backgroundColor: "#F0F0F0",

              boxShadow: " 0 12px 15px 0 rgb(0 0 0 / 25%)",
            }}
          >
            <Row>
              <Col span={24}>
                <h1
                  style={{
                    fontWeight: 500,
                    fontSize: 22,
                    marginLeft: "0.5em",
                    marginTop: "-0.5em",
                    color: "black",
                    whiteSpace: "nowrap",
                  }}
                >
                  Payments Accepted Onsite
                </h1>
                <ul>
                  <li
                    style={{
                      fontWeight: 500,
                      fontSize: 15,
                      marginTop: "0em",
                      color: "black",
                      fontFamily: "Mitr",
                    }}
                  >
                    Electronic Bank Transfer
                  </li>
                  <li
                    style={{
                      fontWeight: 500,
                      fontSize: 15,
                      marginTop: "0em",
                      color: "black",
                      fontFamily: "Mitr",
                    }}
                  >
                    Card payments
                  </li>
                </ul>
              </Col>
            </Row>
          </StyledCard>
        </Col>
      </Row>{" "}
      <Row
        style={{
          margin: "auto",
          marginTop: "1em",
          textAlign: "center",
          padding: "1.1em",
        }}
      >
        <Col span={10}>
          <Link to='/ContactUs'>
            <Button
              className='my-button'
              style={{
                borderRadius: 80,
                fontWeight: 500,
                height: "2.6em",
                paddingRight: "0.5em",
                paddingLeft: "0.5em",
                color: "white",
                border: "none",
                boxShadow: "8px 5px 15px #00000062",
                backgroundColor: "#2D7A75",
                marginBottom: "1em",
                fontFamily: "Mitr",
                width: "90%",
              }}
              type='primary'
              htmlType='submit'
            >
              CONTACT US
            </Button>
          </Link>
        </Col>
        <Col span={14}>
          <Link to='/Bookings'>
            <Button
              className='my-button'
              style={{
                borderRadius: 80,
                fontWeight: 500,
                height: "2.6em",
                paddingRight: "0.5em",
                paddingLeft: "0.5em",
                color: "white",
                border: "none",
                boxShadow: "8px 5px 15px #00000062",
                backgroundColor: "#2D7A75",
                marginTop: "0em",
                fontFamily: "Mitr",
                width: "90%",
              }}
              type='primary'
              htmlType='submit'
            >
              AVAILABLE DATES
            </Button>{" "}
          </Link>
        </Col>
      </Row>{" "}
      <div style={{ paddingLeft: "1em" }}>
        <Row style={{ maxWidth: "90em", margin: "auto", marginTop: "2em" }}>
          {" "}
          <Col span={6}>
            {" "}
            <h1
              style={{
                fontWeight: 500,
                fontSize: 20,

                color: "black",
                whiteSpace: "nowrap",
              }}
            >
              Reviews:
            </h1>
          </Col>{" "}
          <Col span={18} style={{ textAlign: "center" }}>
            <Rate disabled allowHalf defaultValue={4.5} />{" "}
            <span className='ant-rate-text'>4.9</span>
          </Col>
        </Row>
        <Row style={{ maxWidth: "90em", margin: "auto", marginTop: "0.5em" }}>
          {" "}
          <Col span={6}>
            {" "}
            <h1
              style={{
                fontWeight: 500,
                fontSize: 17,
                color: "black",
                whiteSpace: "nowrap",
              }}
            >
              Cleanliness:
            </h1>
          </Col>{" "}
          <Col span={2}></Col>
          <Col span={16}>
            <Progress
              style={{ marginTop: "0.3em" }}
              percent={100}
              status='active'
              format={(percent) => `5.0/5`}
            />
          </Col>
        </Row>
        <Row style={{ maxWidth: "90em", margin: "auto", marginTop: "0.5em" }}>
          {" "}
          <Col span={6}>
            {" "}
            <h1
              style={{
                fontWeight: 500,
                fontSize: 17,

                color: "black",
                whiteSpace: "nowrap",
              }}
            >
              Service:
            </h1>
          </Col>{" "}
          <Col span={2}></Col>
          <Col span={16}>
            <Progress
              style={{ marginTop: "0.3em" }}
              percent={95}
              status='active'
              format={(percent) => `4.9/5`}
            />
          </Col>
        </Row>
        <Row style={{ maxWidth: "90em", margin: "auto", marginTop: "0.5em" }}>
          {" "}
          <Col span={6}>
            {" "}
            <h1
              style={{
                fontWeight: 500,
                fontSize: 17,

                color: "black",
                whiteSpace: "nowrap",
              }}
            >
              Location:
            </h1>
          </Col>{" "}
          <Col span={2}></Col>
          <Col span={16}>
            <Progress
              style={{ marginTop: "0.3em" }}
              percent={95}
              status='active'
              format={(percent) => `4.9/5`}
            />
          </Col>
        </Row>
        <Row style={{ maxWidth: "90em", margin: "auto", marginTop: "0.5em" }}>
          {" "}
          <Col span={6}>
            {" "}
            <h1
              style={{
                fontWeight: 500,
                fontSize: 17,

                color: "black",
                whiteSpace: "nowrap",
              }}
            >
              Safety:
            </h1>
          </Col>{" "}
          <Col span={2}></Col>
          <Col span={16}>
            <Progress
              style={{ marginTop: "0.3em" }}
              percent={100}
              status='active'
              format={(percent) => `5.0/5`}
            />
          </Col>
        </Row>
        <Row style={{ maxWidth: "90em", margin: "auto", marginTop: "0.5em" }}>
          {" "}
          <Col span={6}>
            {" "}
            <h1
              style={{
                fontWeight: 500,
                fontSize: 17,

                color: "black",
                whiteSpace: "nowrap",
              }}
            >
              Value :
            </h1>
          </Col>{" "}
          <Col span={2}></Col>
          <Col span={16}>
            <Progress
              style={{ marginTop: "0.3em" }}
              percent={95}
              status='active'
              format={(percent) => `4.9/5`}
            />
          </Col>
        </Row>{" "}
      </div>
      <Row
        style={{
          maxWidth: "90em",
          margin: "auto",
          marginTop: "4em",
          marginBottom: "1em",
          padding: "1em",
        }}
      >
        {" "}
        <Col span={24}>
          {" "}
          <h1
            style={{
              fontWeight: 500,
              fontSize: 25,

              color: "black",
              whiteSpace: "nowrap",
            }}
          >
            Location:
          </h1>
        </Col>{" "}
        <Col span={24} style={{ textAlign: "center" }}>
          <iframe
            style={{ width: "100%", height: "20em", border: "2px solid white" }}
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3600.391282885937!2d28.492114815645817!3d-25.525340142917738!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1ebffb9e5f7fdd85%3A0x775aa33de1e4c852!2sThala%20Thala!5e0!3m2!1sen!2sza!4v1657843665321!5m2!1sen!2sza'
          ></iframe>
        </Col>{" "}
      </Row>
      <Row
        style={{
          margin: "auto",
          marginTop: "0em",
          padding: "1.1em",
          textAlign: "center",
        }}
      >
        <Col span={10}>
          <Link to='/ContactUs'>
            <Button
              className='my-button'
              style={{
                borderRadius: 80,
                fontWeight: 500,
                height: "2.6em",
                paddingRight: "0.5em",
                paddingLeft: "0.5em",
                color: "white",
                border: "none",
                boxShadow: "8px 5px 15px #00000062",
                backgroundColor: "#2D7A75",
                marginBottom: "1em",
                fontFamily: "Mitr",
                width: "90%",
              }}
              type='primary'
              htmlType='submit'
            >
              CONTACT US
            </Button>
          </Link>
        </Col>
        <Col span={14}>
          <Link to='/Bookings'>
            <Button
              className='my-button'
              style={{
                borderRadius: 80,
                fontWeight: 500,
                height: "2.6em",
                paddingRight: "0.5em",
                paddingLeft: "0.5em",
                color: "white",
                border: "none",
                boxShadow: "8px 5px 15px #00000062",
                backgroundColor: "#2D7A75",
                marginTop: "0em",
                fontFamily: "Mitr",
                width: "90%",
              }}
              type='primary'
              htmlType='submit'
            >
              AVAILABLE DATES
            </Button>{" "}
          </Link>
        </Col>
      </Row>{" "}
      <Footer />
    </div>
  </div>
);

export default Project;
