import React, { Component } from "react";
import { Layout, Icon, Row, Col, Form, Input, Button, Modal } from "antd";
import {
  MailOutlined,
  PhoneOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
import PriceBar from "../static/Home/price-bar.png";
import LogoModal from "../static/Home/main-logo.svg";
import Facebook from "../static/Home/facebook.svg";
import Insta from "../static/Home/insta.svg";
import Whatsapp from "../static/Home/whatsapp.svg";
import FooterBackBlack from "../static/Home/footer-back-black.png";
import FooterLogo1 from "../static/Home/footer-logo-1.png";
import FooterLogo2 from "../static/Home/footer-logo-2.png";
import FooterLogo3 from "../static/Home/footer-logo-3.png";
import { Modal as SuccessModal } from "react-responsive-modal";
import { Link } from "react-router-dom";
import styled from "styled-components";
const StyledModal = styled(Modal)`
  width: 70vw !important;
  margin-top: -5em;
  .ant-modal {
    width: 80vw !important;
  }
  .ant-modal-close-x {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    background-color: black;
    border-radius: 3em;
    display: block;
    width: 40px !important;
    height: 40px !important;
    font-size: 20px !important;
    color: white !important;
    font-style: normal;
    line-height: 38px !important;
    text-align: center;
    text-transform: none;
    text-rendering: auto;
  }
  .ant-modal-mask {
    background-color: white !important;
  }
  .ant-modal-footer {
    display: none;
  }
  .react-responsive-modal-modal {
    background-color: transparent !important;
  }
  .ant-modal-content {
    margin: auto;

    background-color: white !important;
  }
  .ant-modal-body {
    margin: auto;
    width: 50vw;
    overflow: hidden;
    padding-left: 0em;
    background-color: transparent !important;
  }
  .react-responsive-modal-closeButton {
    display: none;
  }
  .react-responsive-modal-closeButton {
    border: none !important;
  }
  .ant-modal-centered .ant-modal {
    width: 99vw !important;
    background-color: transparent !important;
  }
`;
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      placement: "left",
      inputValue: 1,
      FormChange: false,
      visible: false,
      visiblePopup: false,
      visiblePopupDrone: false,
      open: false,
      successModal: false,
      successModalNewsletter: false,
      tabPosition: "left",
    };
    this.changeValue = this.changeValue.bind(this);
    this.onFinish = this.onFinish.bind(this);
  }
  handleTabClick = (key) => {
    this.props.history.push(`/${key}`); // < == router router v4
  };
  componentDidMount() {
    document.title = "Thala-Thala";
    document.documentElement.scrollTop = 0;
  }
  onChange = (e) => {
    this.setState({
      placement: e.target.value,
    });
  };
  onChange = (value) => {
    this.setState({
      inputValue: value,
    });
  };
  showModal = () => {
    this.setState({
      visiblePopup: true,
    });
  };
  handleOk = (e) => {
    console.log(e);
    this.setState({
      visiblePopup: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visiblePopup: false,
    });
  };
  handleClickDemo(form) {
    this.setState({
      FormChangeDemo: form,
    });
  }
  handleClickCall(form) {
    this.setState({
      FormChangeCall: form,
    });
  }
  showModalDrone = () => {
    this.setState({
      visiblePopupDrone: true,
    });
  };
  handleOk1 = (e) => {
    console.log(e);
    this.setState({
      visiblePopupDrone: false,
    });
  };

  handleCancel1 = (e) => {
    console.log(e);
    this.setState({
      visiblePopupDrone: false,
    });
  };
  handleSubmit = (values) => {
    this.onFinish(values);
  };
  changeValue = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  onOpenModal = () => {
    this.setState({ open: true });
  };
  onCloseModal = () => {
    this.setState({ open: false });
  };
  handleCloseSuccessModal = () => {
    this.setState({ successModal: false });
    window.location.assign("./");
  };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };
  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  showModal1 = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };
  onFinish = (values) => {
    const MSG = {
      to: ["dawiedfick@gmail.com"],
      from: "info@ewb.co.za",
      //to: ["dawiedfick@gmail.com", "monique@moniqs-interiors.com"],
      subject: "New contact request",
      text: " ",
      html: `
    
      <style type="text/css">  
      body, #bodyTable{background-color:white}
      #emailHeader{background-color:white}
      #emailBody{background-color:#FFFFFF; border:1px solid #CCCCCC;}
      #emailFooter{background-color:#E1E1E1;}      
</style>
<body bgcolor="#E1E1E1" leftmargin="0" marginwidth="0" topmargin="0" marginheight="0" offset="0">
<center style="background-color:#E1E1E1;">
<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" style="table-layout: fixed;max-width:100% !important;width: 100% !important;min-width: 100% !important;">
  <tr>
    <td align="center" valign="top" id="bodyCell">      
      <table bgcolor="#E1E1E1" border="0" cellpadding="0" cellspacing="0" width="500" id="emailHeader">
        <!-- HEADER ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="10" cellspacing="0" width="500" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                        <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%">
                          <tr>
                       
                            <td align="right" valign="middle" class="flexibleContainerBox">
                              <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:100%;">
                                <tr>
                                  <td align="left" class="textContent">
                                    <!-- CONTENT // -->
                                   
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // END -->
      </table>     
      <!-- EMAIL BODY // -->   
      <table bgcolor="#FFFFFF" border="0" cellpadding="0" cellspacing="0" width="500" id="emailBody">
        <!-- MODULE ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0"   style="color:#FFFFFF;" bgcolor="black">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                 
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                      
                        <table border="0" cellpadding="0" cellspacing="0" width="100%" z-index="100" >
                               <td align="center" valign="top" width="500" class="flexibleContainerCell  ">   
                                <img alt="" src="https://i.ibb.co/Yhg1gg8/logo.png" width="300" height="100" style="display: block; width:195px; max-width: 355px;; min-width: 355px;;border-radius: 8px 8px 8px 8px; font-family: Lato, Helvetica, Arial, sans-serif; color: #9AA31E; font-size: 18px;margin-top: 18px;" border="0">     <tr>
                            <td align="center" valign="top" class="textContent">
                              <h1 style="color:black;line-height:100%;font-family:Helvetica,Arial,sans-serif;font-size:35px;font-weight:normal;margin-bottom:20px;text-align:center;">Contact Request</h1>
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:17px;margin-bottom:10px;color: black;line-height:135%;">You have received a new contact request from <br /> EWB Equipment website</h2>
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->
                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- MODULE ROW // -->
        <tr mc:hideable>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%" height="300" >
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="0" cellspacing="0" width="300" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">
                        <!-- CONTENT TABLE // -->
                        <table p align="left" border="0" cellpadding="0" cellspacing="0" width="120%">
                          <tr>
                            <td align="center" valign="top" class="flexibleContainerBox">
                               <table border="0" cellspacing="0" cellpadding="0" >
            
                        <tr style="font-family: sans-serif; font-size: 17px; letter-spacing: 1px; color:black">
                          <td class="role"><br /><br /><br /><br /><br />
                          <strong> Name </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.name}<br />
                           
                               <strong> Message </strong>&nbsp;&nbsp; :                     &nbsp;&nbsp;${values.message}<br />
                               <strong> Email </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.email}
                             <br /><br /><br />
                          </td>
                        </tr>
                        <table>
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // MODULE ROW -->


        <!-- MODULE ROW // -->
       
<table border="0" cellpadding="0" cellspacing="0" width="100%"  style="color:#FFFFFF;" bgcolor="black" style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:-80px;color: lightgray;line-height:135%;">
              <tr>
                <td align="center" valign="top">
                
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                    
                        <table border="0" cellpadding="30" cellspacing="0" width="100%">
                      
                    
                          <tr>
                            <td align="center" valign="top" class="textContent">
        
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:0px;color: grey;line-height:135%;">2021 Moniq's</h2>
               
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
     `,
    };
    fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(MSG),
    }).then(async (res) => {
      if (!res.ok) {
        console.log("handle error");
      }
      console.log("handle success");
      this.setState({
        successModal: true,
        submitDisabled: true,
        name: "",
        message: "",
        email: "",
      });
    });
  };

  render() {
    return (
      <div
        style={{
          backgroundColor: "#EA8C0E",
          padding: "1em",
          marginTop: "2em",
        }}
      >
        <div
          className='footer-div'
          id='footer'
          style={{
            margin: "auto",
            marginTop: "0.5em",
            backgroundColor: "transparent",
            maxWidth: "100em",
            zIndex: 999,
            paddingTop: "0em",
            height: "18em",
            borderTop: "0px solid lightgrey",
            borderRadius: "0em 0em 0em 0em",
          }}
        >
          <Row
            justify='center'
            className='footer'
            style={{
              margin: "auto",
              paddingTop: "0em",
              zIndex: 999,
              maxWidth: "80em",
              textAlign: "center",
            }}
          >
            <Col span={24}>
              <p
                className='Mitr-footer'
                style={{
                  fontSize: 12,
                  marginTop: "0.5rem",

                  color: "white",
                }}
              >
                <Link to='/' style={{ color: "white", margin: "1em" }}>
                  HOME
                </Link>
                <Link to='/AboutUs' style={{ color: "white", margin: "1em" }}>
                  ABOUT US
                </Link>
                <Link to='/Bookings' style={{ color: "white", margin: "1em" }}>
                  BOOK NOW
                </Link>
                <Link to='/ContactUs' style={{ color: "white", margin: "1em" }}>
                  CONTACT US
                </Link>
              </p>{" "}
            </Col>{" "}
            <Col
              span={24}
              className='footer-logo'
              style={{ textAlign: "center", marginTop: "1rem" }}
            >
              <p
                style={{
                  color: "white",
                  fontWeight: 100,
                  fontFamily: "Mitr",
                  marginLeft: "-0.3em",
                }}
              >
                <PhoneOutlined
                  style={{
                    fontSize: 18,
                    marginRight: "0.3em",
                  }}
                />{" "}
                {"   "} 082 573 4613
                <br />
                <MailOutlined
                  style={{
                    fontSize: 18,
                    marginRight: "0.3em",
                  }}
                />{" "}
                {"   "} dawiedfick@gmail.com
                <br />
                <EnvironmentOutlined
                  style={{
                    fontSize: 18,
                    marginRight: "0.3em",
                  }}
                />
                {"   "}
                Off the R573, Plot 71 Leeukloof, Cullinan, 1000, Gauteng
              </p>{" "}
            </Col>{" "}
          </Row>
          <Row
            style={{
              margin: "auto",
              marginTop: "0em",

              textAlign: "center",
            }}
          >
            <Col span={10}>
              <Link to='/ContactUs'>
                <Button
                  className='my-button'
                  style={{
                    borderRadius: 80,
                    fontWeight: 500,
                    height: "2.6em",
                    paddingRight: "0.5em",
                    paddingLeft: "0.5em",
                    color: "white",
                    border: "none",
                    boxShadow: "8px 5px 15px #00000062",
                    backgroundColor: "#2D7A75",
                    marginBottom: "1em",
                    fontFamily: "Mitr",
                    width: "90%",
                  }}
                  type='primary'
                  htmlType='submit'
                >
                  CONTACT US
                </Button>
              </Link>
            </Col>
            <Col span={14}>
              <Link to='/Bookings'>
                <Button
                  className='my-button'
                  style={{
                    borderRadius: 80,
                    fontWeight: 500,
                    height: "2.6em",
                    paddingRight: "0.5em",
                    paddingLeft: "0.5em",
                    color: "white",
                    border: "none",
                    boxShadow: "8px 5px 15px #00000062",
                    backgroundColor: "#2D7A75",
                    marginTop: "0em",
                    fontFamily: "Mitr",
                    width: "90%",
                  }}
                  type='primary'
                  htmlType='submit'
                >
                  AVAILABLE DATES
                </Button>{" "}
              </Link>
            </Col>
          </Row>{" "}
        </div>{" "}
        <Row style={{ margin: "auto", textAlign: "center", display: "block" }}>
          <p
            style={{
              fontSize: "13px",
              fontWeight: 200,
              fontFamily: "Mitr",
              lineHeight: "0em",
              //marginTop: "1.5em",
              fontFamily: "Mitr",
              color: "white",
            }}
          >
            © Thala-Thala 2022 |{" "}
            <a
              style={{
                fontSize: "13px",
                fontWeight: 200,
                fontFamily: "Mitr",
                lineHeight: "0em",
                //marginTop: "1.5em",
                fontFamily: "Mitr",
                color: "white",
              }}
              href='https://vermelion.net/'
              targer='_blank'
            >
              Developed by Vermelion
            </a>
          </p>{" "}
        </Row>
      </div>
    );
  }
}
export default Footer;
