import React, { useState } from "react";
import { PageHeader, Col, BackTop, Popover } from "antd";
import { Link, NavLink } from "react-router-dom";
import { slide as Burger, Item } from "burger-menu";
import "burger-menu/lib/index.css";

import MainLogo from "../static/Home/main-logo.png";
import "antd/dist/antd.css";

const content = (
  <div>
    <Link to='/VILLA' style={{ color: "white" }}>
      {" "}
      <Item itemKey={"c"} text={"VILLA"}></Item>
    </Link>
    <Link to='/CHALET' style={{ color: "white" }}>
      {" "}
      <Item itemKey={"d"} text={"CHALET"}></Item>
    </Link>
    <Link to='/BOOKINGS' style={{ color: "white" }}>
      {" "}
      <Item itemKey={"b"} text={"AVAILABLE DATES"}></Item>
    </Link>
    {/*<SubMenu title="Union Management">
          <Item itemKey={"notice"} text={"Announcement"}></Item>
          <Item itemKey={"union"} text={"Union Inquiries"}></Item>
          <Item itemKey={"entry"} text={"Entry information"}></Item>
        </SubMenu>*/}
  </div>
);
const BurgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <NavLink
        exact
        activeClassName='active'
        to='/'
        style={{ width: "0em", fontFamily: "Mitr", color: "white" }}
      >
        <Item itemKey={"a"} text={"HOME"}></Item>
      </NavLink>
      <NavLink
        activeClassName='active'
        to='/AboutUs'
        style={{ width: "2em", fontFamily: "Mitr", color: "white" }}
      >
        <Item itemKey={"b"} text={"ABOUT US"}></Item>
      </NavLink>
      <span style={{ width: "2em", fontFamily: "Mitr", color: "white" }}>
        <Popover
          className='burger-container'
          placement='bottomRight'
          content={content}
          trigger='click'
          style={{ width: "2em" }}
        >
          {/*location.pathname === "/home" || location.pathname === "/"*/}
          <Item itemKey={"c"} text={"BOOK NOW"}></Item>
        </Popover>
      </span>
      <NavLink
        activeClassName='active'
        to='/ContactUs'
        style={{ width: "10em", fontFamily: "Mitr", color: "white" }}
      >
        <Item itemKey={"d"} text={"CONTACT US"}></Item>
      </NavLink>
      {/*<SubMenu title="Union Management">
          <Item itemKey={"notice"} text={"Announcement"}></Item>
          <Item itemKey={"union"} text={"Union Inquiries"}></Item>
          <Item itemKey={"entry"} text={"Entry information"}></Item>
        </SubMenu>*/}
    </>
  );
};
const MainHeader = () => (
  <div id='Header' className='site-page-header-ghost-wrapper'>
    <PageHeader
      style={{
        backgroundColor: "transparent",
        maxWidth: "90em",
        width: "90vw",
        margin: "auto",
        padding: 0,
      }}
      ghost={false}
      //  onBack={() => window.history.back()}
      title={
        <Col span={10}>
          <Link to='/'>
            <img
              className='header-logo'
              style={{ width: "17em", height: "3em" }}
              src={MainLogo}
            ></img>{" "}
          </Link>
          <div class='main'>
            <div class='circle'></div>
          </div>
        </Col>
      }
      //  subTitle="This is a subtitle"
      extra={[
        <Col span={14} style={{ display: "contents" }}>
          <BurgerMenu />
        </Col>,
      ]}
    >
      {/*<Descriptions size="small" column={3}>
         <Descriptions.Item label="Created">Lili Qu</Descriptions.Item>
         <Descriptions.Item label="Association">
           <a>421421</a>
         </Descriptions.Item>
         <Descriptions.Item label="Creation Time">2017-01-10</Descriptions.Item>
         <Descriptions.Item label="Effective Time">2017-10-10</Descriptions.Item>
         <Descriptions.Item label="Remarks">
           Gonghu Road, Xihu District, Hangzhou, Zhejiang, China
         </Descriptions.Item>
       </Descriptions>*/}
    </PageHeader>
  </div>
);

const Header = () => (
  <header className='Homepage'>
    {" "}
    <BackTop />
    <MainHeader />
  </header>
);

export default Header;
