import React from "react";
import { Row, Col, Button, Card } from "antd";
import AboutImageHeader from "../static/Home/about-image.png";
import AboutImageHeader1 from "../static/Home/about-image-1.png";
import AboutImageHeader2 from "../static/Home/about-image-2.png";
import AboutImageHeader3 from "../static/Home/about-image-3.png";
import ProductImage1 from "../static/Home/product-image-1.png";
import ProductImage2 from "../static/Home/product-image-2.png";
import ProductImage3 from "../static/Home/product-image-3.png";
import ProductImage4 from "../static/Home/product-image-4.png";
import Sketch3 from "../static/Home/sketch-img-3.png";
import Sketch4 from "../static/Home/sketch-img-4.png";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import Header from "../components/HeaderMobile";
import Footer from "../components/FooterMobile";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import Calendar from "@ericz1803/react-google-calendar";
import { css } from "@emotion/react";
const API_KEY = "AIzaSyDuhiQXw6Lo3zG6kg80cfAA5iODsPn8s8Y";
let calendars = [
  {
    calendarId: "09opmkrjova8h5k5k46fedmo88@group.calendar.google.com",
    color: "#B241D1",
  }, //add a color field to specify the color of a calendar
  { calendarId: "hkr1dj9k6v6pa79gvpv03eapeg@group.calendar.google.com" }, //without a specified color, it defaults to blue (#4786ff)
  {
    calendarId: "rg4m0k607609r2jmdr97sjvjus@group.calendar.google.com",
    color: "rgb(63, 191, 63)",
  }, //accepts hex and rgb strings (doesn't work with color names)
];
let styles = {
  //you can use object styles (no import required)
  calendar: {
    borderWidth: "3px", //make outer edge of calendar thicker
  },

  //you can also use emotion's string styles
  today: css`
    /* highlight today by making the text red and giving it a red border */
    color: rgb(234, 140, 14);
    border: 1px solid rgb(234, 140, 14);
  `,
};
class Bookings extends React.Component {
  render() {
    return (
      <div>
        <div className='mobile-view'>
          <Header />
          <Row style={{ maxWidth: "90em", margin: "auto", marginTop: "6em" }}>
            <Col sm={24} md={24} style={{ margin: "auto", marginTop: "1em" }}>
              <div>
                {/*<Calendar
                  styles={styles}
                  apiKey={API_KEY}
                  calendars={calendars}
                />*/}{" "}
                <iframe
                  src='https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=Africa%2FJohannesburg&title=Thala%20Thala&src=cGxwcWxlcXZiYzNoNWl2MWFuZGI5ajFwYzRAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%23F4511E'
                  style={{ height: "100vh", margin: "auto", width: "90vw" }}
                  frameborder='0'
                  scrolling='no'
                ></iframe>
              </div>
            </Col>{" "}
          </Row>{" "}
          <Footer />
        </div>
      </div>
    );
  }
}

export default Bookings;
