import React, { Component } from "react";
import { Row, Col, Form, Input, Button, Card, Rate } from "antd";
import styled from "styled-components";
import HomeImage from "../static/Home/background-ellipse.png";
import AboutImage from "../static/Home/background-ellipse1.png";
import AboutImageHeader from "../static/Home/about-image.png";
import ProductImage1 from "../static/ThalaThala_Villa_Image/IMG_0099-1.png";
import ProductImage2 from "../static/ThalaThala_Chalet_Image/IMG_5124-1.png";
import StarOutlined from "../static/Home/Star-outlined.svg";
import ProductImage3 from "../static/Home/product-image-3.png";
import ProductImage4 from "../static/Home/product-image-4.png";
import ProductImage5 from "../static/Home/product-image-5.png";
import Header from "../components/Header";
import HomeMobile from "./HomeMobile";
import Footer from "../components/Footer";
import { Modal as SuccessModal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";

import Sketch1 from "../static/Home/sketch-img.png";
import Sketch2 from "../static/Home/sketch-img-1.png";

import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
const { TextArea } = Input;

const handleDragStart = (e) => e.preventDefault();

const StyledInput = styled(Input)`
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 1.2em !important;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: "tnum","tnum";
  font-feature-settings: "tnum","tnum";
  position: relative;
  height: 50px !important;
  padding: 4px 0px;
  background-color: #d9d9d9a2 !important;
  background-image: none;
  border: none;
  border-bottom: 0px solid white;
  border-radius: 2px;
  font-size: 15px !important;
    font-weight: 700 !important;
  -webkit-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;

  color: white!important;
  box-shadow: none !important;
  :placeholder {
    color: white !important;
  }
  :hover {
    border-bottom: 0px solid red !important;
  }
  :active{
    border-bottom: 0px red !important;
  }
  @media only screen and (max-width: 600px) {
  width:100vw;
  margin:auto;
    }
  }
`;

const { Meta } = Card;
const Title = () => (
  <Fade bottom>
    <h1
      className='Mitr-header'
      style={{
        marginTop: "20vh",
        fontWeight: 1000,
        fontSize: 60,
        marginLeft: "0em",
        color: "black",
      }}
    >
      CUSTOMISED PLASTIC ENGINEERING
    </h1>{" "}
    <div className='home-button'>
      <a href='#about-us'>
        <Button
          className='my-button'
          style={{
            borderRadius: 8,
            fontWeight: 1000,
            height: "2.2em",
            width: "9.5em",
            color: "#281562",
            border: "5px solid #281562",
            fontSize: "28px",
            backgroundColor: "transparent",
            marginTop: "2em",
            fontFamily: "Mitr",
          }}
          type='primary'
        >
          LEARN MORE
        </Button>
      </a>{" "}
      <Link to='/ContactUs'>
        <Button
          className='my-button'
          style={{
            borderRadius: 8,
            fontWeight: 500,
            height: "2.2em",
            width: "9.5em",
            color: "white",
            border: "5px solid #281562",
            fontSize: "28px",
            backgroundColor: "#281562",
            marginTop: "2em",
            marginLeft: "2em",
            fontFamily: "Mitr",
          }}
          type='primary'
        >
          CONTACT US
        </Button>
      </Link>
    </div>
  </Fade>
);
const HomeBackEllipse = () => (
  <Fade>
    <img className='home-img' src={HomeImage}></img>{" "}
  </Fade>
);
const AboutBackEllipse = () => (
  <img id='about-us' className='about-img' src={AboutImage}></img>
);
const AboutHeader = () => (
  <div>
    <p
      className='Mitr'
      style={{
        fontWeight: 800,
        fontSize: "50px",
        marginBottom: "-0em",
        fontFamily: "Mitr",
      }}
    >
      ABOUT US
    </p>
    <img src={AboutImageHeader}></img>{" "}
  </div>
);
const AboutParagraph = () => (
  <div>
    <p
      className='Mitr'
      style={{
        fontWeight: 500,
        fontSize: 20,
        marginTop: "1.5rem",
        color: "black",
      }}
    >
      Customised Plastic Products CC is a micro enterprise specializing in HDPE
      and PP sheet and pipe engineering. The company was established in June
      2006 by the owner and Managing Director. Customised Plastic Products
      fabricate various products in their fully equipped factory 30km North of
      Pretoria. In addition they perform an onsite welding and installation
      service. Customised Plastic Products CC is a level 4 B-BBEE contributor
      and has a 100% B-BBEE procurement recognition level.
    </p>{" "}
    <div className='home-button' style={{ textAlign: "center" }}>
      <Link to='/AboutUs'>
        <Button
          className='my-button'
          style={{
            borderRadius: 8,
            fontWeight: 1000,
            height: "2.2em",
            width: "9.5em",
            color: "#281562",
            border: "5px solid #281562",
            fontSize: "28px",
            backgroundColor: "transparent",
            marginTop: "1em",
            fontFamily: "Mitr",
          }}
          type='primary'
        >
          LEARN MORE
        </Button>
      </Link>{" "}
      <Link to='/ContactUs'>
        <Button
          className='my-button'
          style={{
            borderRadius: 8,
            fontWeight: 500,
            height: "2.2em",
            width: "9.5em",
            color: "white",
            border: "5px solid #281562",
            fontSize: "28px",
            backgroundColor: "#281562",
            marginTop: "1em",
            marginLeft: "2em",
            fontFamily: "Mitr",
          }}
          type='primary'
        >
          CONTACT US
        </Button>
      </Link>
    </div>
  </div>
);
const ProductsHeader = () => (
  <div id='product-range'>
    <Fade bottom>
      <h1
        className='Mitr-product-range'
        style={{
          margin: "auto",
          fontWeight: 800,
          fontSize: "35px",
        }}
      >
        OUR PRODUCT RANGE
      </h1>
      <p
        className='Mitr'
        style={{
          fontSize: 18,
          marginTop: "1.5rem",
          color: "black",
        }}
      >
        Our product offerings go way beyond amazing product manufacturing. We
        pride ourselves on supplying as well as maintaining professional
        customer service.
      </p>{" "}
    </Fade>
  </div>
);
const items = [
  <Link to='/VILLA'>
    <Card
      className='item'
      data-value='1'
      style={{
        backgroundImage: "url(" + `${ProductImage1}` + ")",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundColor: "rgba(255, 255, 255, 0.85)",
        boxShadow: " 3px 10px 8px 0 rgb(0 0 0 / 30%)",
        overflow: "hidden",
        margin: "0.5em",
        padding: "1em",
        borderRadius: "1em",
        border: "none",

        textAlign: "center",
        height: "18.5em",
        //width: "30em",
      }}
      // hoverable
    >
      <Meta
        //title='IRRIGATION FLOATING PUMPS'
        description={
          <span>
            <img
              style={{
                position: "absolute",
                left: "0.8em",
                top: "1em",

                borderRadius: "3em",
                width: "2em",
              }}
              src={StarOutlined}
            ></img>
            <span
              className='ant-rate-text'
              style={{
                position: "absolute",
                left: "1.8em",
                top: "0.6em",
                fontWeight: 1000,
                stroke: "red",
                strokeWidth: 3,
              }}
            >
              5.0
            </span>
            <p
              className='Mitr'
              style={{
                fontWeight: 600,
                fontSize: 16,
                textAlign: "right",
                position: "absolute",
                right: "1em",
                bottom: "1em",
                marginTop: "1.5rem",
                color: "white",
                fontFamily: "Mitr",
              }}
            >
              From <span style={{ fontSize: 18, fontWeight: 700 }}>R300 </span>
              per person
              <br />
              Plot 71 Leeukloof Wagensdrif Cullinant
            </p>{" "}
          </span>
        }
      />
      {/*<div className='home-button'>
      <Link to='/Projects'>
        <Button
          className='my-button'
          style={{
            borderRadius: 8,
            fontWeight: 1000,
            height: "2.3em",
            width: "8.5em",
            color: "#281562",
            border: "3px solid #281562",
            fontSize: "17px",
            backgroundColor: "transparent",
            marginTop: "1em",
            fontFamily: "Mitr",
          }}
          type='primary'
        >
          LEARN MORE
        </Button>
      </Link>{" "}
      <Link to='/ContactUs'>
        <Button
          className='my-button'
          style={{
            borderRadius: 8,
            fontWeight: 500,
            height: "2.3em",
            width: "8.5em",
            color: "white",
            border: "3px solid #281562",
            fontSize: "17px",
            backgroundColor: "#281562",
            marginTop: "1em",
            marginLeft: "0.5em",
            fontFamily: "Mitr",
          }}
          type='primary'
        >
          CONTACT US
        </Button>
      </Link>
    </div>*/}
    </Card>
  </Link>,
  <Link to='/CHALET'>
    <Card
      className='item'
      data-value='1'
      style={{
        backgroundImage: "url(" + `${ProductImage2}` + ")",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundColor: "rgba(255, 255, 255, 0.85)",
        margin: "0.5em",
        padding: "1em",
        borderRadius: "1em",
        boxShadow: " 3px 10px 8px 0 rgb(0 0 0 / 30%)",
        overflow: "hidden",
        border: "none",

        textAlign: "center",
        height: "18.5em",
        //width: "30em",
      }}
      // hoverable
    >
      <Meta
        //title='IRRIGATION FLOATING PUMPS'
        description={
          <span>
            {" "}
            <img
              style={{
                position: "absolute",
                left: "0.8em",
                top: "1em",

                borderRadius: "3em",
                width: "2em",
              }}
              src={StarOutlined}
            ></img>
            <span
              className='ant-rate-text'
              style={{
                position: "absolute",
                left: "1.8em",
                top: "0.6em",
                fontWeight: 1000,
                stroke: "red",
                strokeWidth: 3,
              }}
            >
              4.9
            </span>
            <p
              className='Mitr'
              style={{
                fontWeight: 600,
                fontSize: 16,
                textAlign: "right",
                position: "absolute",
                right: "1em",
                bottom: "1em",
                marginTop: "1.5rem",
                color: "white",
                fontFamily: "Mitr",
              }}
            >
              From <span style={{ fontSize: 18, fontWeight: 700 }}>R450 </span>
              per person
              <br />
              Plot 71 Leeukloof Wagensdrif Cullinant
            </p>{" "}
          </span>
        }
      />
      {/*<div className='home-button'>
      <Link to='/Projects'>
        <Button
          className='my-button'
          style={{
            borderRadius: 8,
            fontWeight: 1000,
            height: "2.3em",
            width: "8.5em",
            color: "#281562",
            border: "3px solid #281562",
            fontSize: "17px",
            backgroundColor: "transparent",
            marginTop: "1em",
            fontFamily: "Mitr",
          }}
          type='primary'
        >
          LEARN MORE
        </Button>
      </Link>{" "}
      <Link to='/ContactUs'>
        <Button
          className='my-button'
          style={{
            borderRadius: 8,
            fontWeight: 500,
            height: "2.3em",
            width: "8.5em",
            color: "white",
            border: "3px solid #281562",
            fontSize: "17px",
            backgroundColor: "#281562",
            marginTop: "1em",
            marginLeft: "0.5em",
            fontFamily: "Mitr",
          }}
          type='primary'
        >
          CONTACT US
        </Button>
      </Link>
    </div>*/}
    </Card>
  </Link>,
];
const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 2 },
};

const ContactHeader = () => (
  <div id='contact-us'>
    <Fade>
      <h1
        style={{
          margin: "auto",
          marginTop: "-0.5em",
          lineHeight: "1.4em",
          fontWeight: 800,
          fontSize: "42px",
          color: "white",
          fontFamily: "Mitr",
        }}
      >
        <strong>
          Tranquility <br />
          Personified
        </strong>
      </h1>
    </Fade>
  </div>
);

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      successModal: false,
    };
    this.changeValue = this.changeValue.bind(this);
    this.onFinish = this.onFinish.bind(this);
  }
  handleTabClick = (key) => {
    this.props.history.push(`/${key}`); // < == router router v4
  };
  componentDidMount() {
    document.title = "Thala-Thala";
    document.documentElement.scrollTop = 0;
  }
  onChange = (e) => {
    this.setState({
      placement: e.target.value,
    });
  };
  onChange = (value) => {
    this.setState({
      inputValue: value,
    });
  };
  showModal = () => {
    this.setState({
      visiblePopup: true,
    });
  };
  handleOk = (e) => {
    console.log(e);
    this.setState({
      visiblePopup: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visiblePopup: false,
    });
  };
  handleClickDemo(form) {
    this.setState({
      FormChangeDemo: form,
    });
  }
  handleClickCall(form) {
    this.setState({
      FormChangeCall: form,
    });
  }
  showModalDrone = () => {
    this.setState({
      visiblePopupDrone: true,
    });
  };
  handleOk1 = (e) => {
    console.log(e);
    this.setState({
      visiblePopupDrone: false,
    });
  };

  handleCancel1 = (e) => {
    console.log(e);
    this.setState({
      visiblePopupDrone: false,
    });
  };
  handleSubmit = (values) => {
    this.onFinish(values);
  };
  changeValue = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleCloseSuccessModal = () => {
    this.setState({ successModal: false });
    window.location.assign("./");
  };

  onFinish = (values) => {
    const MSG = {
      to: ["dawiedfick@gmail.com"],
      from: "info@thalathala.co.za",
      //to: ["dawiedfick@gmail.com", "monique@moniqs-interiors.com"],
      subject: "New contact request",
      text: " ",
      html: `
    
      <style type="text/css">  
      body, #bodyTable{background-color:white}
      #emailHeader{background-color:white}
      #emailBody{background-color:#FFFFFF; border:1px solid #CCCCCC;}
      #emailFooter{background-color:#E1E1E1;}      
</style>
<body bgcolor="#E1E1E1" leftmargin="0" marginwidth="0" topmargin="0" marginheight="0" offset="0">
<center style="background-color:#E1E1E1;">
<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" style="table-layout: fixed;max-width:100% !important;width: 100% !important;min-width: 100% !important;">
  <tr>
    <td align="center" valign="top" id="bodyCell">      
      <table bgcolor="#E1E1E1" border="0" cellpadding="0" cellspacing="0" width="500" id="emailHeader">
        <!-- HEADER ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="10" cellspacing="0" width="500" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                        <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%">
                          <tr>
                       
                            <td align="right" valign="middle" class="flexibleContainerBox">
                              <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:100%;">
                                <tr>
                                  <td align="left" class="textContent">
                                    <!-- CONTENT // -->
                                   
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // END -->
      </table>     
      <!-- EMAIL BODY // -->   
      <table bgcolor="#FFFFFF" border="0" cellpadding="0" cellspacing="0" width="500" id="emailBody">
        <!-- MODULE ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0"   style="color:#FFFFFF;" bgcolor="#7A2D58">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                 
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                      
                        <table border="0" cellpadding="0" cellspacing="0" width="100%" z-index="100" >
                               <td align="center" valign="top" width="500" class="flexibleContainerCell  ">   
                                <img alt="" src="https://i.ibb.co/2KWh5fH/main-logo-b97d91d14d2897b5caf2.png" width="300" height="100" style="display: block; width:404px; max-width: 404px;; min-width: 189px;;border-radius: 8px 8px 8px 8px; font-family: Lato, Helvetica, Arial, sans-serif; color: #FFFFFF; font-size: 18px;margin-top: 18px;" border="0">     <tr>
                            <td align="center" valign="top" class="textContent">
                              <h1 style="color:#FFFFFF;line-height:100%;font-family:Helvetica,Arial,sans-serif;font-size:35px;font-weight:normal;margin-bottom:20px;text-align:center;">Contact Request</h1>
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:17px;margin-bottom:10px;color:#FFFFFF;line-height:135%;">You have received a new contact request from <br /> Thala-Thala website</h2>
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->
                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- MODULE ROW // -->
        <tr mc:hideable>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%" height="300" >
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="0" cellspacing="0" width="300" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">
                        <!-- CONTENT TABLE // -->
                        <table p align="left" border="0" cellpadding="0" cellspacing="0" width="120%">
                          <tr>
                            <td align="center" valign="top" class="flexibleContainerBox">
                               <table border="0" cellspacing="0" cellpadding="0" >
            
                        <tr style="font-family: sans-serif; font-size: 17px; letter-spacing: 1px; color:black">
                          <td class="role"><br /><br />
                          <strong> Name </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.nameMobile}<br />
                          <strong> Email </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.emailMobile}<br />
                          <strong> Number </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.numberMobile}<br />
                          <strong> Message </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.messageMobile}<br />
                            
                          </td>
                        </tr>
                        <table>
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // MODULE ROW -->


        <!-- MODULE ROW // -->
       
<table border="0" cellpadding="0" cellspacing="0" width="100%"  style="color:#FFFFFF;" bgcolor="#FFFFFF" style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:-80px;color: lightgray;line-height:135%;">
              <tr>
                <td align="center" valign="top">
                
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                    
                        <table border="0" cellpadding="30" cellspacing="0" width="100%">
                      
                    
                          <tr>
                            <td align="center" valign="top" class="textContent">
        
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:0px;color: grey;line-height:135%;">2022@Thala-Thala</h2>
               
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
     `,
    };
    fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(MSG),
    }).then(async (res) => {
      if (!res.ok) {
        console.log("handle error");
      }
      console.log("handle success");
      this.setState({
        successModal: true,
        submitDisabled: true,
        nameMobile: "",
        emailMobile: "",
        numberMobile: "",
        messageMobile: "",
      });
    });
  };

  render() {
    return (
      <div>
        <HomeMobile />
        <div className='desktop-view'>
          {" "}
          <Header />
          <HomeBackEllipse />{" "}
          <img
            className='contact-image'
            style={{
              left: 0,
              zIndex: -1,
              height: "80vh",
              width: "100vw",
              minHeight: "45em",
              position: "absolute",
            }}
            src={Sketch2}
          ></img>
          <Row
            style={{
              maxWidth: "100em",
              width: "100vw",
              margin: "auto",
              textAlign: "center",

              minHeight: "40em",
              height: "calc(80vh - 4em)",
            }}
          >
            {" "}
            <Col sm={24} md={9} className='home-col'>
              <div
                style={{
                  margin: "auto",
                  textAlign: "center",
                  position: "absolute",
                  top: "50%",
                  left: "12%",
                  msTransform: "translateY(-50%)",
                  transform: "translateY(-50%)",
                  backgroundColor: "#292929bd",
                  borderRadius: "1.8em",
                  maxWidth: "29em",
                }}
              >
                <Row
                  style={{
                    margin: "auto",
                    textAlign: "center",
                    paddingTop: "2em",
                  }}
                >
                  <Col sm={24} md={24}>
                    <ContactHeader />
                  </Col>
                </Row>
                <Row
                  style={{
                    maxWidth: "60em",
                    margin: "auto",
                    textAlign: "center",
                    marginTop: "1em",
                  }}
                >
                  <Col sm={24} md={24}>
                    <Fade>
                      <Form
                        onFinish={this.handleSubmit}
                        initialValues={{
                          remember: true,
                        }}
                        name='wrap'
                        labelCol={{ flex: "110px" }}
                        labelAlign='left'
                        labelWrap
                        wrapperCol={{ flex: 1 }}
                        colon={false}
                      >
                        <Row style={{}}>
                          <Col sm={24} md={24}>
                            <Form.Item
                              name='nameMobile'
                              rules={[
                                {
                                  required: true,
                                  message: "Name is required",
                                },
                              ]}
                            >
                              <StyledInput
                                style={{ width: "90%" }}
                                placeholder='NAME & SURNAME'
                              />
                            </Form.Item>
                          </Col>

                          <Col sm={24} md={24}>
                            <Form.Item
                              name='emailMobile'
                              rules={[
                                {
                                  required: true,
                                  message: "Email is required",
                                },
                              ]}
                            >
                              <StyledInput
                                style={{ width: "90%" }}
                                placeholder='EMAIL'
                              />
                            </Form.Item>
                          </Col>

                          <Col sm={24} md={24}>
                            <Form.Item
                              name='numberMobile'
                              rules={[
                                {
                                  required: true,
                                  message: "Number is required",
                                },
                              ]}
                            >
                              <StyledInput
                                style={{
                                  width: "90%",
                                  message: "Number is required",
                                }}
                                placeholder='NUMBER'
                              />
                            </Form.Item>
                          </Col>

                          <Col sm={24} md={24}>
                            <Form.Item
                              name='messageMobile'
                              rules={[
                                {
                                  required: true,
                                  message: "Message is required",
                                },
                              ]}
                            >
                              <TextArea
                                style={{ width: "90%" }}
                                rows={3}
                                placeholder='MESSAGE'
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Form.Item>
                          <Button
                            className='my-button'
                            style={{
                              borderRadius: 80,
                              fontWeight: 500,
                              height: "2.4em",
                              width: "9.5em",
                              color: "white",
                              border: "none",

                              fontSize: "23px",
                              backgroundColor: "#2D7A75",
                              marginTop: "0em",

                              fontFamily: "Mitr",
                            }}
                            type='primary'
                            htmlType='submit'
                          >
                            SUBMIT
                          </Button>
                        </Form.Item>
                      </Form>
                    </Fade>
                  </Col>
                </Row>{" "}
              </div>
            </Col>
            <Col span={1}></Col>
            <Col
              sm={24}
              md={14}
              className='home-col'
              style={{ marginTop: "31vh" }}
            >
              <AliceCarousel
                infinite={true}
                //disableDotsControls={true}
                disableButtonsControls={true}
                mouseTracking
                items={items}
                responsive={responsive}
                controlsStrategy='alternate'
              />
            </Col>
          </Row>
          <SuccessModal
            open={this.state.successModal}
            onClose={this.handleCloseSuccessModal}
            center
          >
            {/*<img src={LogoModal} alt='Logo' style={{ width: "65%" }} />*/}
            <h1
              style={{
                marginTop: "1em",
                color: "black",
                textAlign: "center",
                fontSize: "50px",
                fontWeight: 500,
              }}
            >
              THANK YOU <br />
              <p
                style={{
                  marginTop: "-0.8em",
                  color: "black",
                  textAlign: "center",
                  fontSize: "27px",
                  fontWeight: 500,
                }}
              >
                FOR YOUR MESSAGE
              </p>{" "}
            </h1>{" "}
            <p
              style={{
                marginTop: "em",
                color: "black",
                lineHeight: 1,
                textAlign: "center",
                fontSize: "23px",
                fontWeight: 500,
              }}
            >
              We shall get back to
              <br /> you as soon as possible.
            </p>{" "}
            <br />
            <Button
              className='my-button'
              onClick={this.handleCloseSuccessModal}
              style={{
                borderRadius: 80,
                fontWeight: 400,
                height: "2.2em",
                width: "9.5em",
                color: "white",
                border: "5px solid #2D7A75",
                fontSize: "24px",
                backgroundColor: "#2D7A75",
                marginTop: "-1em",

                fontFamily: "Mitr",
              }}
              type='primary'
            >
              GO HOME
            </Button>{" "}
          </SuccessModal>{" "}
          <Footer />
        </div>
      </div>
    );
  }
}

export default Home;
